export const jobs = [
  {
    title: "Software Developer",
    companyTitle: "Paycom Payroll, LLC.",
    location: "Dallas, Texas",
    startDate: "May 2023",
    endDate: "Present",
    bulletPoints: [
      "Demonstrated programming in MySQL, TypeScript, ReactJS, C#, PHP.",
      "Improved backend API performance and fronted UI/UX on Single Page Applications.",
      "Maintained an interactive I9 Form application with sophisticated UI between multiple users.",
      "Utilized development tools (Docker, Git, Jira, etc.) to facilitate SDLC process.",
      "Collaborated with Product Management, QA, and other Developers to ensure reliable products and mitigate software bugs.",
    ],
  },
  {
    title: "Applications Engineering Intern",
    companyTitle: "Texas Instruments, Inc.",
    location: "Richardson, Texas",
    startDate: "May 2022",
    endDate: "August 2022",
    bulletPoints: [
      "Programmed in Python (Tkinter), Verilog, and C.",
      "Designed GUI Framework for rapid prototyping and testing of High Speed Data Converters.",
    ],
  },
  {
    title: "Farm Hand",
    companyTitle: "B&D McWhirter Farms",
    location: "Plains, TX",
    startDate: "October 2012",
    endDate: "May 2022",
    bulletPoints: [
      "Image crops circles via drone.",
      "Utilized Climate FieldView and Drone Deploy apps to map fields to experiment with and predict product yield.",
      "Operate all farm machinery (ATVs, forklifts, tractors, spray rigs and pivots).",
      "Manage water pressure.",
      "Troubleshoot mechanical failure in equipment or processes.",
      "Work with chemical application imperative to crop production.",
      "Haul materials and equipment to various locations efficiently.",
    ],
  },
  {
    title: "Ropes Counselor",
    companyTitle: "Camp Timberline",
    location: "Estes Park, CO",
    startDate: "May 2020",
    endDate: "July 2020",
    bulletPoints: [
      "Fostered a healthy Christian environment for children from ages 6-17",
      "Obtained certification in Wilderness First Aid (WFA) and Cariopulmonary Resuscitation (CPR)",
      "Obtained certification for on-site harnessed activities that include a Zip Line, High Ropes Course, Rock Wall, etc.",
    ],
  },
  {
    title: "SPC Campus Ambassador",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "August 2019",
    endDate: "December 2019",
    bulletPoints: [
      "Provided campus tours to guests interested in South Plains College (SPC).",
      "Served as usher at all SPC graduation ceremonies.",
      "Represented SPC in interviews, publications and commercials",
      "Provided service to all scholarship events hosted at SPC.",
      "Utilized drone to provide video footage of campus for television commercials",
    ],
  },
  {
    title: "Math Tutor",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "August 2018",
    endDate: "December 2019",
    bulletPoints: [
      "Utilized interpersonal skills to successfully tutor students from a variety of cultural backgrounds in mathematical courses as advanced as Calculus II.",
      "Programmed in-class assignments with C++ language and currently learning Object-Oriented Programming.",
    ],
  },
  {
    title: "UIL Invitational Speech Competition Judge",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "March 2019",
    endDate: undefined,
    bulletPoints: [
      "Judged and ranked student competitors of informative speaking, poetry and prose",
      "Evaluated eye contact, clarity, emotion, relevance, relatability and originality",
    ],
  },
];
